<template>
  <Nav @isReg="isRegistered = $event" />
  <div class="flex">
    <div class="rig-window">
      <Signup v-if="!isRegistered" />
      <Login v-else />
      <div v-if="!isRegistered" class="foot">
        Already Registered?
        <Button class="p-button-info p-button-text" @click="toggleAuth"
          >Sign in</Button
        >
      </div>
      <div v-else class="foot">
        Not Registered? then
        <Button class="p-button-info p-button-text" @click="toggleAuth"
          >Register</Button
        >
      </div>
    </div>
    <div class="display">
      <img src="../assets/photos/home.jpg" alt="image is not available" />
    </div>
  </div>
  <Foot />
</template>

<script>
import { onMounted, ref } from "vue";
import getStorage from "../modules/getStorage";
import Signup from "../components/signup";
import Login from "../components/login";
import Nav from "../components/nav";
export default {
  components: { Signup, Login, Nav },
  setup() {
    const file = ref(null);
    const storageError = ref(null);
    const isRegistered = ref(true);
    const toggleAuth = () => {
      isRegistered.value = !isRegistered.value;
    };
    // onMounted(async () => {
    //   const { getFile, getStorageError } = await getStorage("home", "home.jpg");
    //   file.value = getFile.value;
    //   storageError.value = getStorageError.value;
    // });
    return { file, storageError, isRegistered, toggleAuth };
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.rig-window {
  min-width: 40%;
  padding-left: 10px;
  padding-right: 10px;
}
img {
  width: 100%;
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  img {height: 100%;}
}
@media (max-width: 900px) {
  .display {
    display: none;
  }
  .flex {
    display: block;
  }
  .p-col-6 {
    width: 100%;
  }
}
.toggle-auth {
  color: rgb(42, 54, 165);
  font-size: 1.4rem;
}
.p-button-text {
  padding-top: 5px;
}
.foot {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
</style>