import { ref } from "vue";
import { auth } from "../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";

const updatePassword = async (email) => {
  const updatePasswordError = ref(null);
  try {
    await sendPasswordResetEmail(auth, email, {
      url: "https://rheum-minds.com",
      handleCodeInApp: true,
    });
    updatePasswordError.value = "Email Sent";
  } catch (err) {
    updatePasswordError.value = err.message;
  }
  return { updatePasswordError };
};
export default updatePassword;
