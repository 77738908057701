import { ref } from "vue";
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

const useSignup = async (email, password, displayName) => {
  const error = ref(null);
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    if (res) {
      await updateProfile(res.user, { displayName: displayName });
    } else {
      error.value = "Registration Failed... please try again";
    }
  } catch (err) {
    console.log(err);
    error.value = err.message;
  }
  return { error };
};
export default useSignup;
