import { ref } from "vue"
import { db } from "../firebase/config";
import { doc,setDoc,updateDoc,arrayUnion } from "firebase/firestore";

const addPeople = async (email) => {
    const addPeopleError = ref(null);
    try {
        await setDoc(doc(db, "mindsPeople", email), {
            email: email,
            websites: []
        })
    } catch (err) {
        addPeopleError.value = err
        console.log(err);
    }
    return { addPeopleError }
}
const addWebsite = async (email, website) => {
    const addWebsiteError = ref(null);
    try {
        await updateDoc(doc(db, "mindsPeople", email), "websites", arrayUnion(website))
    } catch (err) {
        addWebsiteError.value = err
        console.log(err);
    }
    return { addWebsiteError }
}
export default addPeople
export { addWebsite }