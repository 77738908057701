class Course {
  constructor(
    vidTitle,
    vidSubtitle,
    presentation,
    presentationVid,
    story,
    downloadPresentation,
    tabs
  ) {
    this.vidTitle = vidTitle;
    this.vidSubtitle = vidSubtitle;
    this.presentation = presentation;
    this.presentationVid = presentationVid;
    this.story = story;
    this.downloadPresentation = downloadPresentation;
    this.tabs = tabs;
  }
}
class Tab {
  constructor(head, video, downloadLink) {
    this.head = head;
    this.video = video;
    this.downloadLink = downloadLink;
  }
}
const courses = [
  new Course(
    "Case #1: Dr Tom Appleton",
    "Improving clinical recognition of rheumatologic disease",
    "Watch Dr. Appleton's Full Case Presentation",
    "https://vimeo.com/722634191/a59c9b568e",
    "Watch Frank's Story",
    "https://player.vimeo.com/progressive_redirect/download/722634191/rendition/1080p/dr_appleton_-_full_video%20%281080p%29.mp4?loc=external&signature=6eb5c496ef3968a55ed23a6dae9abc6022f4bbc2add78d05f84bfec30ee6b8ca",
    [
      new Tab(
        "Seg 1 - Case Objectives and Patient Referral",
        "https://vimeo.com/710491730/23635b7a20",
        "https://player.vimeo.com/progressive_redirect/download/710491730/rendition/1080p/segment_1:_case_introduction%20%281080p%29.mp4?loc=external&signature=8ed311c0d37fdb8d6108451f41db1c6aa4b7ffb110c2d79f0bc824ec7f6c6f0e"
      ),
      new Tab(
        "Seg 2 - Rheumatology Assessment",
        "https://vimeo.com/710491607/e882ee3273",
        "https://player.vimeo.com/progressive_redirect/download/710491607/rendition/1080p/segment_2:_rheumatology_assessment%20%281080p%29.mp4?loc=external&signature=f6ba1ddcc51edc7d600ccc50d8d524f5e56f1e72e6882ba9ec2509e5515349dc"
      ),
      new Tab(
        "Seg 3 - Labs and Imaging",
        "https://vimeo.com/710491572/a2596876b0",
        "https://player.vimeo.com/progressive_redirect/download/710491572/rendition/1080p/segment_3:_imaging_investigations%20%281080p%29.mp4?loc=external&signature=cfa3651861d5293903dc66d2372beb1c8d72fc7faf7ecc97c1e7eb5cff3c2ba7"
      ),
      new Tab(
        "Seg 4 - Role of anti-CCP",
        "https://vimeo.com/710491541/9e07bb85b4",
        "https://player.vimeo.com/progressive_redirect/download/710491541/rendition/1080p/segment_4:_role_of_anti-ccp%20%281080p%29.mp4?loc=external&signature=ffaa2a52a9aacc61793e5ffa7b2494642d31a8067e3b009b0ed6c757b9a516c0"
      ),
      new Tab(
        "Seg 5 - Ambiguity of Diagnosis and Treatment Guideline Considerations",
        "https://vimeo.com/710491439/915fd6eeb7",
        "https://player.vimeo.com/progressive_redirect/download/710491439/rendition/1080p/segment_5:_other_treatment_considerations%20%281080p%29.mp4?loc=external&signature=b3971a2821cf0cebaa471ce893032ccccfee764ca6028f11b5768f9aaaca75cd"
      ),
      new Tab(
        "Seg 6 - Treatment and 12 week Follow Up",
        "https://vimeo.com/710491381/e04964b3de",
        "https://player.vimeo.com/progressive_redirect/download/710491381/rendition/1080p/segment_6:_first_line_treatment%20%281080p%29.mp4?loc=external&signature=4519c45aaaf7fa23e9fe6a9fdeff1280d36f480a0a0173ef2c3c3a5696a5b56e"
      ),
      new Tab(
        "Seg 7 - Further Treatment Options",
        "https://vimeo.com/710491303/23b45962aa",
        "https://player.vimeo.com/progressive_redirect/download/710491303/rendition/1080p/segment_7:_second_line_treatment%20%281080p%29.mp4?loc=external&signature=a6a8a401664a8dfa191b044a47fcfdff36d4d614bc8a273920a0fe9611cab07c"
      ),
      new Tab(
        "Seg 8 - Disease Features and Response to Therapy",
        "https://vimeo.com/710495520/e2101a1a99",
        "https://player.vimeo.com/progressive_redirect/download/710495520/rendition/1080p/segment_8:_second_line_treatment_outcomes%20%281080p%29.mp4?loc=external&signature=fafc7944b7790263237c6605552eb59fb9d36b342c5696451f961e2ace9b883f"
      ),
      new Tab(
        "Seg 9 - Add Adjunct Therapy or Change ",
        "https://vimeo.com/710507051/e929851636",
        "https://player.vimeo.com/progressive_redirect/download/710507051/rendition/1080p/segment_9:_third_line_treatment%20%281080p%29.mp4?loc=external&signature=e73860dc71ef80e644e3df852ee16798934e06707c50a8dcd3355cf575a1a2f7"
      ),
      new Tab(
        "Seg 10 - 3rd Line TRx and Outcomes",
        "https://vimeo.com/710611213/4a49dd5a98",
        "https://player.vimeo.com/progressive_redirect/download/710611213/rendition/1080p/segment_10:_third_line_treatment_outcomes%20%281080p%29.mp4?loc=external&signature=bb4bb73075457adf748e117b8f9e741b12159d3fc93119d7db787359d100e58b"
      ),
      new Tab(
        "Seg 11 - Case Conclusion",
        "https://vimeo.com/710609996/69fe560fb5",
        "https://player.vimeo.com/progressive_redirect/download/710609996/rendition/1080p/reflection_and_conclusion%20%281080p%29.mp4?loc=external&signature=7e1c7d55a403764be8314cc6f5595537942186820becdee957a0de14386cb817"
      ),
      new Tab(
        "Optional - Unconscious Bias",
        "https://vimeo.com/718796788/5a3e1049dc",
        "https://player.vimeo.com/progressive_redirect/download/718796788/rendition/1080p/segment_11-_unconscious_bias%20%281080p%29.mp4?loc=external&signature=8663e72efd061ace2fffed0abe48f27d8e3a92d36fbcc4b7d0b7f8fd38d9e8d2"
      ),
      new Tab("Module complete", ""),
    ]
  ),
  new Course(
    "Case #2: Dr. Hugues Allard-Chamard",
    "A Not-So-Rare, Wonderful and Dreadful Case of Arthritis",
    "Watch Dr. Allard-Chamard's Full Case Presentation",
    "https://vimeo.com/808825234/76c51551de",
    "",
    " https://player.vimeo.com/progressive_redirect/download/808825234/rendition/1080p/rheumatology_case_-_dr_allard-chamard%20%281080p%29.mp4?loc=external&signature=937eaa1dee77914bcf0bcf1111ff8d78a0fd1007a4d0e4a18895e85545665a48", [
    new Tab(
      "Seg 1 - Case Introduction",
      "https://vimeo.com/808420830/a63293e648",
      "https://player.vimeo.com/progressive_redirect/download/808420830/rendition/1080p/rheumatology_case_-_dr_allard-chamard_-_ch_1%20%281080p%29.mp4?loc=external&signature=acbfbc76713fa752b25935aaf9df83c1c39640b642573abf476152ba09a30c07"),
    new Tab(
      "Seg 2 - First Biologic",
      "https://vimeo.com/808421171/f9b449dd1b",
      "https://player.vimeo.com/progressive_redirect/download/808421171/rendition/1080p/rheumatology_case_-_dr_allard-chamard_-_ch_2%20%281080p%29.mp4?loc=external&signature=f772ffc1b5d3953a57707646f49a3fc38d8867f4bf27a34598bd1340c6cb373e"),
    new Tab(
      "Seg 3 - Second Line",
      "https://vimeo.com/808421362/51bd9dd335",
      "https://player.vimeo.com/progressive_redirect/download/808421362/rendition/1080p/rheumatology_case_-_dr_allard-chamard_-_ch_3%20%281080p%29.mp4?loc=external&signature=52a70df1bdb1262fb162da40a0a84e39c729dad068dc7ce8f6ea951318fb4f5b"),
    new Tab(
      "Seg 4 - Finding CVID",
      "https://vimeo.com/808421684/2695f29cae",
      "https://player.vimeo.com/progressive_redirect/download/808421684/rendition/1080p/rheumatology_case_-_dr_allard-chamard_-_ch_4%20%281080p%29.mp4?loc=external&signature=d3f4b06bbabd1ba77401982d77aa2518fa4f0c18ccd72b76951c8cb1f98b3f46"),
    new Tab(
      "Seg 5 - Treatment and Conclusion",
      "https://vimeo.com/808421840/430235b415",
      " https://player.vimeo.com/progressive_redirect/download/808421840/rendition/1080p/rheumatology_case_-_dr_allard-chamard_-_ch_5%20%281080p%29.mp4?loc=external&signature=eacfeb21435364bdb5605559710585a88a7ee24a0b64f0f72c93b12a16eee0fd"),
    new Tab("Module complete", ""),
  ]
  ),
];

class Case {
  constructor(cas, dr, report) {
    this.case = cas;
    this.dr = dr;
    this.report = report;
  }
}
const cases = [
  new Case(
    "Rheum-1",
    "Case #1: Dr Tom Appleton",
    "Improving clinical recognition of rheumatologic disease"
  ),
  new Case(
    "Rheum-2",
    "Case #2: Dr. Hugues Allard-Chamard",
    "A Not-So-Rare, Wonderful and Dreadful Case of Arthritis"
  ),
];

class Prov {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
const prov = [
  new Prov("Alberta", "alberta"),
  new Prov("British Columbia", "britishColumbia"),
  new Prov("Manitoba", "manitoba"),
  new Prov("New Brunswick", "newBrunswick"),
  new Prov("Newfoundland and Labrador", "newfoundlAndLabrador"),
  new Prov("Northwest Territories", "northwestTerritories"),
  new Prov("Nova Scotia", "novaScotia"),
  new Prov("Nunavut", "Nunavut"),
  new Prov("Ontario", "Ontario"),
  new Prov("Prince Edward Island", "princeEdwardIsland"),
  new Prov("Quebec", "Quebec"),
  new Prov("Saskatchewan", "Saskatchewan"),
  new Prov("Yukon", "Yukon"),
];

class Pract {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
const pract = [
  new Pract("Doctor", "doctor"),
  new Pract("Pharmacist", "pharmacist"),
  new Pract("Nurse", "nurse"),
];

const licMask = {
  doctor: {
    alberta: [6],
    britishColumbia: [5],
    manitoba: [5],
    newBrunswick: [5, 7],
    newfoundlAndLabrador: [5],
    novaScotia: [6],
    Ontario: [5, 6],
    princeEdwardIsland: [3, 4],
    Quebec: [5],
    Saskatchewan: [6],
  },
  pharmacist: {
    alberta: [4, 5],
    britishColumbia: [5],
    manitoba: [5],
    newBrunswick: [4],
    newfoundlAndLabrador: [5, 6],
    northwestTerritories: [5, 9],
    novaScotia: [3, 4, 5],
    Ontario: [6],
    Quebec: [6],
    Saskatchewan: [4, 5],
    Yukon: [9, 8],
  },
  nurse: {
    alberta: [5, 6],
    britishColumbia: [5, 7],
    manitoba: [6],
    newBrunswick: [6],
    newfoundlAndLabrador: [5],
    northwestTerritories: [4, 5, 9],
    novaScotia: [5, 6],
    Nunavut: [3, 4],
    Ontario: [7, 8],
    princeEdwardIsland: [6],
    Quebec: [6, 7],
    Saskatchewan: [7],
  },
};

export default courses;
export { prov, pract, licMask, cases };
